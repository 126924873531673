<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
    </div>

    <div class="v" v-if="uploaded && uploaded.done">
          <span>导入结果：共{{uploaded.res.total}}条,成功{{uploaded.res.success}}条,失败{{uploaded.res.fail}}条</span>&nbsp;
          <div class="h c">
            <span>错误详情</span> &nbsp;
            <el-button @click="downloadErr" :loading="downloadLoading" type="danger" size="mini">错误导出</el-button>
          </div>
          &nbsp;
          <el-table :data="uploaded.res.detail" border style="width: 100%;">
            <el-table-column prop="code" width="120" label="经销商编码" />
            <el-table-column prop="name" width="200" label="经销商名称" />
            <el-table-column prop="amount" width="80" header-align="center" align="center" label="充值金额" />
            <el-table-column prop="info" width="150" show-overflow-tooltip label="备注" />
            <el-table-column prop="error" label="错误信息" width="150" show-overflow-tooltip />
          </el-table> 
        </div>
        <div v-if="uploaded && uploaded.done && uploaded.res.err">
          <span>{{uploaded.res.err}}</span>
        </div>

  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      uploading: false,
      entId: null,
      entName:null,
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      uploaded:{done:false,warehouse:{},stock:{}},
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
    uploadUrl() {
      return config.hosts.request + "api/payForm/import/";
    },
  },
  methods: {
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {
      this.uploaded.done = true;
      this.uploaded.res = res;
      this.uploading = false;
     
    },
    handleUploadError(err) {
      //this.errInfo = err;
      this.$message.err(err);
      this.uploading = false;
    },
    downloadTemplate() {
      this.downloadLoading = true;
      download("api/payForm/import/template")
        .then((result) => {
          downloadFile(result, "客户批量充值模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    downloadErr(){
      if(!this.uploaded.res.detail || this.uploaded.res.detail.length===0) return;
      
      this.downloadLoading = true;
      import("@/utils/export2Excel").then(excel => {
        const tHeader = [
          "经销商编码",
          "经销商名称",
          "充值金额",
          "备注",
          "错误信息"
        ];
        const filterVal = [
          "code",
          "name",
          "amount",
          "info",
          "error"
        ];
        const data = this.formatJson(filterVal, this.uploaded.res.detail);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "充值单未导入信息"
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {          
            return v[j];          
        })
      );
    },
    resetForm(entId,entName) {
      this.uploadHeaders.Authorization = "Bearer " + getToken();      
      this.uploaded={done:false,res:{}};
      this.dialog = true;
    },
  },
};
</script>